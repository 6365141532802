<template>
  <div @click="dialogVisible = false">
    <div class="resume-diagnosis-wrapper">
      <div class="bg-wrapper">
        <img src="@/assets/image/resume-diagnosis-bg.png" />
      </div>
      <div class="resume-diagnosis-top">
        <img src="@/assets/image/resume-diagnosis-top.png" />
      </div>
      <div class="content-fixed">
        <div class="title-box">
          <img src="@/assets/image/resume-diagnosis-title.png" />
          <!-- 头像 -->
          <div class="avatar-box">
            <img
              :src="userInfoList.data.avatar"
              class="List-Img"
              v-if="userInfoList && userInfoList.data.avatar"
            />
            <div class="close" @click="confirm">退出</div>
          </div>
        </div>
        <div class="center-content">
          <div class="content-box">
            <div class="title-bar">
              <div class="title-name">
                <div class="left">
                  <img src="@/assets/image/title-icon.png" />
                  <div class="name">上传简历</div>
                </div>
              </div>
              <div class="title-line">
                <img src="@/assets/image/title-line.png" />
              </div>
            </div>
            <div class="upload-files-box">
              <el-upload
                ref="fileUp"
                :on-success="upFileSuccessFn"
                :action="`${youPaiYunUrl}/interview-tzkj`"
                :show-file-list="false"
                :data="upyunData"
                :drag="true"
                accept=".doc,.docx,application/pdf"
                class="upload-demo"
                multiple
              >
                <div v-if="saveObj.resume_url" class="already-up-status">
                  <div class="files-box">
                    <img
                      v-if="fileFormatFn(saveObj.resume_name) === 'doc'"
                      src="@/assets/image/word-icon.png"
                    />
                    <img
                      v-if="fileFormatFn(saveObj.resume_name) === 'pdf'"
                      src="@/assets/image/pdf-icon.png"
                    />
                    <div class="name">{{ saveObj.resume_name }}</div>
                  </div>
                  <img class="more-icon" src="@/assets/image/more-icon2.png" />
                </div>

                <!-- <div class="Online-resume" v-else-if="userInfo.id && userInfo.all_result_n">
                  <div class="WordTextBox">
                    <img src="@/assets/image/iconWrod.png" alt="" class="WordImg" />
                    <span class="BoxText">{{ userInfo.name ? userInfo.name : 'xxx' }}-个人简历</span>
                  </div>
                  <div class="line-Img"><img src="@/assets/image/箭头 (1).png" alt="" /></div>
                </div> -->

                <div v-else class="upload-files-icon">
                  <img src="@/assets/image/upload-files-icon.png" />
                  <div class="name">选择文件</div>
                </div>
              </el-upload>
            </div>
            <div class="title-bar">
              <div class="title-name">
                <div class="left">
                  <img src="@/assets/image/title-icon.png" />
                  <div class="name">需要诊断的职位</div>
                </div>
              </div>
              <div class="title-line">
                <img src="@/assets/image/title-line.png" />
              </div>
            </div>
            <div @click="drawer = true" class="select-box">
              <div class="name">
                {{ saveObj.position_name || '请选择诊断岗位' }}
              </div>
              <img src="@/assets/image/more-icon2.png" />
            </div>
            <div class="left-footer">
              <div v-if="userObj" class="participant-box">
                <div class="text">已有{{ userObj.count }}人参与分析</div>
                <div v-if="userObj.list.length" class="participant-bar">
                  <div v-for="(item, index) in userObj.list" :key="index" class="item">
                    <img :src="item.info.avatar" />
                  </div>
                </div>
              </div>
              <div class="btn-wrapper">
                <div @click="onResumeDiagnosisSaveFn" class="btn">开始诊断</div>
              </div>
            </div>
          </div>
          <div class="content-box">
            <div class="title-bar">
              <div class="title-name">
                <div class="left">
                  <img src="@/assets/image/title-icon.png" />
                  <div class="name">
                    诊断报告
                    <span v-if="reportObj">{{ reportObj.create_time }}</span>
                  </div>
                </div>
                <div
                  class="historical-report"
                  @click.stop="dialogVisible = true"
                  v-if="diagnosisList && diagnosisList.length"
                >
                  诊断历史报告
                </div>
              </div>
              <div class="title-line">
                <img src="@/assets/image/title-line.png" />
              </div>
            </div>
            <div class="report-content">
              <div v-if="reportStatus === 0" class="generate-box">
                <div class="files-icon">
                  <img class="files-icon1" src="@/assets/image/files-icon2.png" />
                  <img class="files-icon2 active" src="@/assets/image/files-icon3.png" />
                  <!-- :class="{ active: callBackObj.show_status !== 3 && callBackObj.report_status === 0 }" -->
                </div>
                <div class="files-title">正在生成诊断报告…</div>
                <div class="files-text">生成报告可能需要1-3分钟，请耐心等待</div>
              </div>
              <div class="report-box" v-if="reportStatus === 1">
                <iframe class="report-iframe" :src="report_url" frameborder="no" />
                <div class="shade-box">
                  <img class="shade-img" src="@/assets/image/shade-img.png" />
                  <div class="qr-content">
                    <img class="qr-box" src="@/assets/image/qr-box.png" />
                    <div class="qr-img">
                      <img class="qr-box" :src="qrUrl" />
                    </div>
                  </div>
                  <div class="report-Btn" @click="resumeReport(null)">查看报告</div>
                  <div class="text">
                    <div>或者微信扫一扫体验完整报告</div>
                  </div>
                </div>
              </div>
              <div v-if="reportStatus === 2" class="generate-box">
                <div class="files-icon">
                  <img class="files-icon1" src="@/assets/image/files-icon.png" />
                </div>
                <div class="files-title">诊断失败</div>
                <div class="files-text">出错了，请重新载入</div>
                <div @click="onResumeDiagnosisSaveFn" class="files-btn">重新载入</div>
              </div>
              <div v-if="reportStatus === null" class="no-data-box">
                <img src="@/assets/image/no-data-icon.png" />
                <div class="text">暂无最新报告记录</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="is_brand === 1" class="footer-box">
          <div class="footer-center">
            <div class="line"></div>
            <div class="name">广西梯子科技有限公司</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <!-- 选择岗位 -->
      <el-drawer :visible.sync="drawer" :with-header="false" direction="ltr" size="420px">
        <div class="drawer-box">
          <el-input
            v-model="search"
            @change="getResumeDiagnosisPositionListFn"
            placeholder="请输入职位名称"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="job-box">
            <div v-if="jobLists.length" class="job-ul">
              <div
                v-for="(item, index) in jobLists"
                @click="onSelectPositionFn(item)"
                :key="index"
                class="item"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-else class="no-data">暂无数据</div>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 菜单弹窗 -->
    <MenuStructure class="menuStructure" type="/resume/diagnosis" />
    <!-- 查看历史诊断记录报告弹窗 -->
    <transition name="fade" mode="out-in">
      <div v-show="dialogVisible" class="historicalReport-box open-dialog">
        <div class="historicalReport-dialog">
          <div class="closeIcon">
            <img src="@/assets//image/close-icon.png" @click.stop="dialogVisible = false" />
          </div>
          <div class="title">历史报告</div>
          <!-- 记录列表 -->
          <div class="recordList-box" v-if="diagnosisList">
            <div
              class="record-box"
              v-for="(item, index) in diagnosisList"
              :key="index"
              @click.stop="resumeReport(item.id)"
            >
              <div class="record-left">
                <img src="@/assets/image/resumeIndex/word.png" alt="" v-if="item.word" />
                <img src="@/assets/image/resumeIndex/pdf.png" alt="" v-else />
                <div class="record-centBox">
                  <div class="record-name">{{ item.resume_name }}</div>
                  <div class="record-time">诊断时间：{{ item.create_time }}</div>
                </div>
              </div>
              <div class="viewThe">
                <span class="grade">{{ item.resume_score || '--' }}</span>
                <span class="minute">分</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { upyunUrl, youPaiYunUrl, H5Url, platform } from '@/api/config'
import MenuStructure from '@/components/MenuStructure'

let reportUrl = null // H5诊断报告链接

if (platform === 'msyz') {
  reportUrl = 'pages/resume/resumeDiagnosisReport/index'
} else if (platform === 'digitalEmployment') {
  reportUrl = 'pages/resumeSystem/diagnosis/resumeDiagnosisReport/index'
}

let sTopTime = null

export default {
  components: {
    MenuStructure //菜单弹窗
  },
  data() {
    return {
      diagnosisList: [], // 诊断报告历史记录
      dialogVisible: false, // 历史诊断报告弹窗状态
      userInfoList: '', // 头像
      userInfo: '', // 用户信息
      reportObj: '', // 最新报告内容
      youPaiYunUrl,
      upyunData: { policy: '', signature: '' },
      drawer: false,
      userObj: null,
      search: '', // drawer搜索
      jobLists: [],
      saveObj: {
        position_id: 0,
        position_name: '',
        resume_file_size: 0,
        resume_id: 0,
        resume_name: '',
        resume_url: ''
      },
      report_url: null, // 报告路径
      callBackObj: null, // 诊断报告结果
      reportStatus: null, // 诊断报告状态 0 = 诊断中，1 = 诊断成功，2 = 诊断失败
      qrUrl: null, // 获取报告二维码
      is_brand: 0 // 是否显示公司名称
    }
  },
  async created() {
    // 获取头像
    if (JSON.parse(sessionStorage.getItem('userInfo'))) {
      this.userInfoList = JSON.parse(sessionStorage.getItem('userInfo'))
    }
    this.getDiagnosisListFn()
    await this.getUpYunSignFn()
    await this.getCurrentResume()
    await this.getResumeGenerateContentFn()
    this.startDiagnosisFn()
    this.getResumeDiagnosisPositionListFn()
    this.getResumeDiagnosisParticipantListFn()
    if (this.$router.history.current.query.is_brand) {
      this.is_brand = parseInt(this.$router.history.current.query.is_brand)
    } else {
      this.is_brand = 0
    }
  },
  computed: {
    ...mapState(['currentResumeEditId'])
  },
  methods: {
    ...mapActions([
      'getResumeDiagnosisPositionList',
      'getResumeDiagnosisParticipantList',
      'resumeDiagnosisSave',
      'getResumeDiagnosisRecord',
      'getShareQrcodeMa',
      'getResumeGenerateContent',
      'getUserInfo',
      'getResumeDiagnosisReport',
      'getDiagnosisList'
    ]),
    ...mapActions(['getUpYunSign']),
    ...mapMutations({
      setToken: 'SET_TOKEN',
      removerContents: 'REMOVER_CONTENTS',
      setCurrentResumeInfo: 'SET_CURRENT_RESUME_INFO',
      setCurrentResumeEditId: 'SET_CURRENT_RESUME_EDIT_ID'
    }),
    // 进入页面判断是否有url和职位id在触发诊断
    startDiagnosisFn() {
      if (this.saveObj.resume_url && this.saveObj.position_id) this.onResumeDiagnosisSaveFn()
    },
    // 获取当前用户的简历pdf
    getCurrentResume() {
      if (JSON.parse(sessionStorage.getItem('resumeFile'))) {
        const resumeFile = JSON.parse(sessionStorage.getItem('resumeFile'))
        this.saveObj.resume_id = ''
        this.saveObj.resume_url = resumeFile.url
        this.saveObj.resume_file_size = resumeFile.resume_file_size
        this.saveObj.resume_name = `${resumeFile.resume_name}.pdf`
      }
    },
    // 获取用户信息 提取意向岗位name和id
    async getResumeGenerateContentFn() {
      const id = this.$route.query.ditId || this.currentResumeEditId
      // 如果不为0说明是携带了用户id过来诊断的 如果为0说明要么上次没有进行诊断 或者上次的诊断还没有完成 防止取值最新的诊断职位 进行缓存
      if (id) {
        this.userInfo = await this.getResumeGenerateContent({ id: id })
        sessionStorage.setItem('userInfoObj', JSON.stringify(this.userInfo))
      } else {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfoObj'))
      }
      await this.saveObjFn()
    },
    // 赋值意向岗位name和id
    saveObjFn() {
      this.saveObj.position_id =
        this.userInfo && this.userInfo.intention && this.userInfo.intention.length
          ? this.userInfo.intention[0].intentionId
          : ''
      this.saveObj.position_name =
        this.userInfo && this.userInfo.intention && this.userInfo.intention.length
          ? this.userInfo.intention[0].intentionName
          : ''
    },
    // 获取简历诊断历史报告
    async getDiagnosisListFn() {
      const res = await this.getDiagnosisList({ page_size: 20 })
      if (res && res.data && res.data.length) {
        res.data.forEach(item => {
          if (!item.resume_url.includes('pdf')) item['word'] = '1'
        })
        this.diagnosisList = res.data
      }
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 文件上传又拍云成功后
    upFileSuccessFn(res, file) {
      this.saveObj.resume_id = ''
      this.saveObj.resume_url = upyunUrl + res.url
      this.saveObj.resume_file_size = file.size
      this.saveObj.resume_name = file.name
    },
    // 岗位列表
    async getResumeDiagnosisPositionListFn() {
      this.jobLists = []
      this.jobLists = await this.getResumeDiagnosisPositionList({
        search: this.search
      })
    },
    // 参与用户
    async getResumeDiagnosisParticipantListFn() {
      this.userObj = await this.getResumeDiagnosisParticipantList()
    },
    // 选择诊断岗位
    onSelectPositionFn(item) {
      this.saveObj['position_id'] = item.id
      this.saveObj['position_name'] = item.name
      this.drawer = false
    },
    // 开始诊断简历
    async onResumeDiagnosisSaveFn() {
      if (!this.saveObj.resume_url && !this.saveObj.resume_id) {
        this.$message({
          message: '请上传诊断简历!',
          type: 'warning',
          offset: 100
        })
        return false
      }
      if (!this.saveObj.position_id) {
        this.$message({
          message: '请选择诊断岗位!',
          type: 'warning',
          offset: 100
        })
        return false
      }
      this.$message({
        message: '开始诊断中...',
        type: 'success',
        offset: 100
      })
      if (platform !== 'msyz') this.reportStatus = 0
      await this.resumeDiagnosisSave(this.saveObj)
      this.getResumeDiagnosisRecordFn()
    },
    // 获取简历诊断提交记录
    async getResumeDiagnosisRecordFn() {
      this.callBackObj = await this.getResumeDiagnosisRecord()
      clearInterval(sTopTime)
      if (this.callBackObj.show_status !== 1) {
        // 简历还没申请诊断
        this.reportStatus = null
        console.log('简历还没申请诊断')
      } else if (this.callBackObj.show_status !== 3 && this.callBackObj.report_status === 0) {
        // 简历诊断中...
        this.reportStatus = 0
        sTopTime = setInterval(() => {
          this.getResumeDiagnosisRecordFn()
        }, 30000)
      } else if (this.callBackObj.show_status !== 3 && this.callBackObj.report_status === 1) {
        // 简历诊断成功
        await this.getResumeDiagnosisReportFn()
        // 成功之后清除本地pdf缓存和职位
        sessionStorage.removeItem('userInfoObj')
        sessionStorage.removeItem('resumeFile')
        sessionStorage.removeItem('resume_title')
        // 再次获取最新的诊断历史报告
        this.getDiagnosisListFn()
      } else if (this.callBackObj.show_status !== 3 && this.callBackObj.report_status === 2) {
        // 简历诊断失败
        this.reportStatus = 2
        console.log('诊断失败')
      }
    },
    // 获取简历报告内容（别人生成的）
    async getResumeDiagnosisReportFn() {
      const obj = await this.getResumeDiagnosisReport(this.callBackObj.id)
      this.reportObj = obj
      this.report_url = `${H5Url}${reportUrl}?report_id=${obj.id}`
      this.getShareQrcodeSceneFn(obj.id)
    },
    // 生成小程序二维码
    async getShareQrcodeSceneFn(id) {
      this.qrUrl = await this.getShareQrcodeMa({
        scene: `id=${id}`,
        page: reportUrl
      })
      this.reportStatus = 1
    },
    // 文件格式处理
    fileFormatFn(name) {
      if (name.indexOf('.doc') !== -1 || name.indexOf('.docx') !== -1) {
        return 'doc'
      } else if (name.indexOf('.pdf') !== -1) {
        return 'pdf'
      }
    },
    resumeReport(id) {
      const { href } = this.$router.resolve({
        path: `/resume/pc_report?id=${id ? id : this.reportObj.id}` // 跳转路径
      })
      window.open(href, '_blank')
    },
    // 退出登录按钮
    confirm() {
      this.removerContents()
      this.$router.push('/resume/login')
    },
    // 离开页面触发的方法
    async leaveFn() {
      // 在每次离开页面的时候清空vuex的数据 确保回来的时候进行接口的请求获取最新数据
      await this.setCurrentResumeInfo(null)
      // // 在每次离开前把id默认为0
      await this.setCurrentResumeEditId(0)
    }
  },
  // 组件内的路由前置守卫
  async beforeRouteLeave(to, from, next) {
    await this.leaveFn()
    next()
  }
}
</script>

<style lang="stylus" scoped>
.resume-diagnosis-wrapper
  position relative
  width 100%
  height auto
  .bg-wrapper
    width 100%
    height auto
    background-color rgba(1, 26, 84, 1)
    img
      display block
      width 100%
      height auto
  .resume-diagnosis-top
    position absolute
    left 0
    top 10px
    width 100%
    height auto
    z-index 9
    img
      display block
      width 100%
      height auto
  .content-fixed
    position fixed
    top 0
    left 50%
    width 1200px
    height 900px
    margin-left -600px
    z-index 10
    .title-box
      width 100%
      height auto
      padding-top 25px
      img
        display block
        width 220px
        height 40px
        margin 0 auto
      .avatar-box
        position absolute
        top 13px
        right -60px
        width 88px
        .close
          cursor pointer
          line-height 34px
          padding-left 46px
          height 34px
          background #3364c9
          border-radius 4px
          font-size 16px
          font-family PingFang SC
          font-weight 500
          color #fff
          box-sizing border-box
        .List-Img
          position absolute
          top 1px
          width 28px
          height 28px
          border-radius 50%
          overflow hidden
          margin 3px 0 0 54px
          right 49px
      .close:hover
        background #809edd
    .center-content
      width 100%
      height auto
      padding-top 65px
      display flex
      justify-content space-between
      .content-box
        position relative
        width 590px
        height 900px
        padding 15px 20px
        box-sizing border-box
        background-color rgba(11, 94, 243, 0.2)
        .title-bar
          width 100%
          height auto
          .title-name
            width 100%
            height auto
            display flex
            align-items center
            justify-content space-between
            .left
              display flex
              align-items center
              img
                display block
                width 23px
                height 23px
              .name
                padding 0 5px
                font-size 22px
                font-weight 500
                line-height 54px
                display flex
                color #fff
                box-sizing border-box
            .historical-report
              width 120px
              height 32px
              background #003DBB
              border-radius 4px
              font-size 16px
              font-weight 400
              color #FFFFFF
              text-align center
              line-height 32px
            .historical-report:hover
              cursor pointer
              opacity 0.8
                span
                  padding-left 5px
                  font-size 16px
                  line-height 54px
            .right
              font-size 20px
              font-weight 400
              color #008AFF
          .on-line
            cursor pointer
            width 120px
            height 32px
            background #003DBB
            border-radius 4px
            border 0px
            font-size 16px
            font-family Source Han Sans CN
            font-weight 400
            color #FFFFFF
            line-height 32px
          .title-line
            width 100%
            height auto
            img
              display block
              width 100%
              height auto
        .upload-files-box
          width 100%
          height 200px
          margin 30px 0 25px 0
          border 1px solid #008AFF
          cursor pointer
          border-radius 10px
          &:active
            background-color #0b378e
          .upload-files-icon
            width 65px
            height 65px
            margin 75px auto 0 auto
            img
              display block
              width 21px
              height 21px
              margin 0 auto
            .name
              margin-top 13px
              font-size 16px
              font-weight 400
              color #fff
          .already-up-status
            width 100%
            height 100%
            padding 0 30px 0 20px
            display flex
            justify-content space-between
            align-items center
            box-sizing border-box
            .files-box
              display flex
              align-items center
              img
                display block
                width 24px
                height 24px
              .name
                padding 0 7px
                font-size 16px
                font-weight 400
                color #fff
            .more-icon
              display block
              width 14px
              height 14px
        .select-box
          width 100%
          height 70px
          padding 0 30px 0 20px
          margin 30px 0 25px 0
          border 1px solid #008AFF
          border-radius 10px
          display flex
          justify-content space-between
          align-items center
          cursor pointer
          box-sizing border-box
          .name
            line-height 16px
            font-size 16px
            font-weight 400
            color #fff
          img
            display block
            width 14px
            height 14px
        .select-box:active
          background-color #0b378e
        .left-footer
          position absolute
          left 0
          bottom 40px
          width 100%
          height auto
          padding 0 20px
          z-index 99
          box-sizing border-box
          .participant-box
            width 100%
            height auto
            padding-bottom 45px
            .text
              width 100%
              height auto
              font-size 16px
              text-align center
              color #fff
            .participant-bar
              width 100%
              height auto
              padding-top 20px
              display flex
              justify-content center
              .item
                width 38px
                height 38px
                margin-left -10px
                border-radius 50%
                border 2px solid #fff
                overflow hidden
                background-color #fff
                img
                  display block
                  width 100%
                  height 100%
                  border 50%
          .btn-wrapper
            width 100%
            height auto
            .btn
              width 100%
              height auto
              line-height 72px
              text-align center
              font-size 20px
              font-weight bold
              cursor pointer
              box-shadow 0 9px 18px 0 rgba(15, 43, 176, 0.24)
              border-radius 10px
              color #fff
              background-color #003dbb
              &:active
                background-color #f8e276
        .report-content
          width 100%
          height auto
          .generate-box
            width 100%
            height auto
            padding-top 200px
            .files-icon
              position relative
              width 50px
              height 50px
              margin 0 auto
              .files-icon1
                display block
                width 100%
                height 100%
              .files-icon2
                position absolute
                left -2px
                top 0
                display block
                width 54px
                height 17px
                z-index 10
              .active
                animation mymove 1.5s linear infinite
            .files-title
              padding 25px 0 18px 0
              font-size 20px
              text-align center
              font-weight 500
              line-height 20px
              color #fff
            .files-text
              text-align center
              font-size 16px
              font-weight 400
              color rgba(255, 255, 255, 0.5)
            .files-btn
              width 250px
              height auto
              margin 50px auto 0 auto
              line-height 72px
              text-align center
              font-size 20px
              font-weight bold
              border-radius 10px
              cursor pointer
              color #34271D
              background-color #FFDD38
            .files-btn:active
              background-color #f8e276
          .report-box
            position relative
            width 100%
            height 775px
            margin-top 30px
            border-radius 10px
            box-sizing border-box
            overflow hidden
            .report-Btn
              cursor pointer
              margin 15px auto
              text-align center
              width 120px
              height 44px
              line-height 44px
              font-size 18px
              font-family Source Han Sans CN
              font-weight bold
              color #34271D
              background #FFDD38
              box-shadow 0px 9px 18px 0px rgba(15, 43, 176, 0.24)
              border-radius 10px
            .report-iframe
              width 100%
              height 100%
              filter blur(3px)
            .shade-box
              position absolute
              left 0
              top 0
              right -1px
              bottom -1px
              z-index 10
              .shade-img
                position absolute
                left 0
                top 0
                width 100%
                height 100%
                z-index -1
              .qr-content
                position relative
                width 204px
                height 204px
                margin 440px auto 0 auto
                .qr-box
                  display block
                  width 100%
                  height 100%
                .qr-img
                  position absolute
                  left 15px
                  top 16px
                  width 172px
                  height 172px
                  background-color #fff
                  img
                    display block
                    width 100%
                    height 100%
              .text
                display flex
                justify-content center
                width 100%
                height auto
                line-height 16px
                font-size 16px
                color #fff
                opacity 0.5
                .report
                  color #008AFF
                  border-bottom 1px solid #008AFF
                  cursor pointer
          .no-data-box
            width 200px
            height auto
            padding-top 175px
            margin 0 auto
            line-height 50px
            img
              display block
              width 100%
              height auto
            .text
              font-size 16px
              font-weight 400
              text-align center
              color #fff
    .footer-box
      width 100%
      height auto
      padding 15px 0
      .footer-center
        width 265px
        height auto
        margin 0 auto
        display flex
        align-items center
        justify-content space-between
        .line
          width 24px
          height 1px
          background-color rgba(255, 255, 255, 0.4)
        .name
          line-height 18px
          color rgba(255, 255, 255, 0.4)
  .drawer-box
    width 100%
    height 100%
    padding 80px 35px 30px 35px
    overflow hidden
    display flex
    flex-direction column
    background-color #031B54
    box-sizing border-box
    .job-box
      flex 1
      width 100%
      height auto
      margin 16px 0
      overflow-y auto
      &::-webkit-scrollbar
        display none
      .job-ul
        width 100%
        height auto
        .item
          width 100%
          height auto
          line-height 64px
          cursor pointer
          font-size 16px
          font-weight 400
          border-bottom 1px solid rgba(0, 138, 255, 0.5)
          color #fff
          &:hover
            color #008AFF
      .no-data
        text-align center
        line-height 100px
        color #fff
@keyframes mymove
  0%
    top 0
  100%
    top 40px
</style>
<style lang="stylus" scoped>
.el-input__inner
  background rgba(0, 138, 255, 0.08) !important
  border 1px solid #008AFF !important
  color #fff !important
.el-input__icon
  color #008AFF !important
.upload-demo, .el-upload, .el-upload-dragger
  width 100% !important
  height 100% !important
  border transparent !important
  background-color transparent !important
>>>.el-upload.el-upload--text
  width 100%
  height 100%
  .el-upload-dragger
    background-color rgb(3, 37, 132)
    border none
    width 100%
    height 100%
.drawer-box input::-webkit-input-placeholder
  color #a4adc6 !important
.menuStructure
  z-index 999
  top 393px
  right 60px
  position fixed
.Online-resume
  display flex
  justify-content space-between
  align-items center
  height 100%
  .WordImg
    width 18px
    height 22px
  .BoxText
    font-size 16px
    font-family Source Han Sans CN
    font-weight 400
    color #FFFFFF
    margin-left 9px
  .WordTextBox
    display flex
    margin-left 23px
  .line-Img
    margin-right 31px
.historicalReport-box
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.7)
  z-index 99
  .historicalReport-dialog
    z-index 999
    width 500px
    height 411px
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background url('~@/assets/image/report-bj.png')
    background-size 100% 100%
    .closeIcon
      display flex
      justify-content flex-end
      margin 15px 24px 0 0
      img
        width 28px
        height 28px
      img:hover
        cursor pointer
    .title
      font-size 24px
      font-weight 500
      color #FFFFFF
      text-align center
      margin-bottom 20px
    .recordList-box
      height 270px
      margin-top 10px
      padding 0 42px
      box-sizing border-box
      overflow hidden
      overflow-y scroll
      .record-box
        display flex
        justify-content space-between
        align-items center
        border-bottom 1px solid #092C6A
        padding 12px 0
        .record-left
          display flex
          align-items center
          img
            width 33px
            height 35px
            margin-right 12px
          .record-centBox
            .record-name
              font-size 14px
              font-weight 400
              color #FFFFFF
            .record-time
              font-size 12px
              font-weight 400
              color #C6C8CD
              margin-top 7px
        .viewThe
          font-size 22px
          font-weight 400
          color #0180ef
          opacity 0.75
          display flex
          align-items center
          .minute
            font-size 14px
            font-weight 400
            margin 3px 0px 0 3px
      .record-box:hover
        cursor pointer
        .viewThe
          .grade
            text-decoration underline
    .recordList-box::-webkit-scrollbar
      display none
.open-dialog
  animation slide-down 0.5s ease
.close-dialog
  animation slide-up 0.5s ease
.open-dialog-enter-active, .open-dialog-leave-active
  transition transform 0.5s ease
.open-dialog-enter, .open-dialog-leave-to
  transform translateY(-100%)
.fade-enter-active, .fade-leave-active
  transition opacity 0.5s
.fade-enter, .fade-leave-to
  opacity 0
</style>
